import {lazy} from "react";
import i18next from "i18next";

import {authRoles} from "app/auth/index.js";
import en from "./i18n/en.js";
import fr from "./i18n/fr.js";

i18next.addResourceBundle("en", "auth", en);
i18next.addResourceBundle("fr", "auth", fr);

const LoginConfig = {
  settings: {layout: {config: {}}},
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/login",
      exact: true,
      component: lazy(() => import("./Login.js")),
    },
    {
      path: "/register",
      exact: true,
      component: lazy(() => import("./Register.js")),
    },
  ],
};

export default LoginConfig;
