const domain = "https://nft.beta.keeex.me";
const version = "v1";

const baseURL = `${domain}/api/${version}`;
const nftTokenKey = "nft_access_jwt";

const apiConfig = {
  baseURL,
  nftTokenKey,
};

export default apiConfig;
